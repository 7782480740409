<template>
  <table class="table-fixed bg-luense-green text-gray-800">
    <tr class="text-left border-b-2 border-gray-300 text-white">
      <th class="px-4 py-3 text-sm md:text-base xl:text-lg">Art.Nr.</th>
      <th class="px-4 py-3 text-sm md:text-base xl:text-lg">Artikelname</th>
      <th class="px-4 py-3 text-sm md:text-base xl:text-lg">Hersteller</th>
      <th class="px-4 py-3 text-sm md:text-base xl:text-lg">Preis</th>
      <th class="px-4 py-3 text-sm md:text-base xl:text-lg">Verfügbar</th>
      <th class="px-4 py-3 text-sm md:text-base xl:text-lg">Lagerplatz</th>
    </tr>

    <tr class="h-4 bg-luense-background border-b-2 border-gray-300">
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>

    <tr
      v-for="index in rows"
      :key="index"
      class="bg-white hover:bg-gray-100 border-b-2 border-r-2 border-l-2 border-gray-300"
    >
      <td class="px-4 text-left py-3">
        <div class="bg-gray-300 rounded-md animate-pulse">
          <span class="invisible">loading</span>
        </div>
      </td>
      <td class="px-4 text-left py-3">
        <div class="bg-gray-300 rounded-md animate-pulse">
          <span class="invisible">loading</span>
        </div>
      </td>
      <td class="px-4 text-left py-3">
        <div class="bg-gray-300 rounded-md animate-pulse">
          <span class="invisible">loading</span>
        </div>
      </td>
      <td class="px-4 text-left py-3">
        <div class="bg-gray-300 rounded-md animate-pulse">
          <span class="invisible">loading</span>
        </div>
      </td>
      <td class="px-4 text-left py-3">
        <div class="bg-gray-300 rounded-md animate-pulse">
          <span class="invisible">loading</span>
        </div>
      </td>
      <td class="px-4 text-left py-3">
        <div class="bg-gray-300 rounded-md animate-pulse">
          <span class="invisible">loading</span>
        </div>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  props: ["count"],
  computed: {
    rows: function () {
      if (this.count > 20) {
        return 20;
      } else {
        return this.count;
      }
    },
  },
  methods: {},
};
</script>

<style></style>
