<template>
  <!-- header -->
  <div
    class="table--header flex w-full bg-luense-green text-left border-b-2 border-gray-300 text-white px-4 py-3 mb-3"
  >
    <div class="w-1/2 text-md md:text-base xl:text-lg">Artikelnummer</div>
    <div class="w-1/2 text-md md:text-base xl:text-lg">Name</div>
  </div>

  <!-- rows -->
  <div
    v-for="index in rows"
    :key="index"
    class="table--row flex flex-col w-full bg-white hover:bg-gray-100 border-l-2 border-r-2 border-b-2 border-gray-300"
  >
    <!-- basic -->
    <div class="flex w-full text-sm px-4 py-3 gap-3">
      <div class="w-1/2 bg-gray-300 rounded-md animate-pulse">
        <span class="invisible">loading</span>
      </div>
      <div class="w-1/2 bg-gray-300 rounded-md animate-pulse">
        <span class="invisible">loading</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["count"],
  computed: {
    rows: function () {
      if (this.count > 20) {
        return 20;
      } else {
        return this.count;
      }
    },
  },
  methods: {},
};
</script>

<style></style>
