<template>
  <table class="table-fixed bg-luense-green text-gray-800">
    <tr class="text-left border-b-2 border-gray-300 text-white">
      <th class="px-4 py-3 text-sm md:text-base xl:text-lg">Art.Nr.</th>
      <th class="px-4 py-3 text-sm md:text-base xl:text-lg">Artikelname</th>
      <th class="px-4 py-3 text-sm md:text-base xl:text-lg">Hersteller</th>
      <th class="px-4 py-3 text-sm md:text-base xl:text-lg">Preis</th>
      <th class="px-4 py-3 text-sm md:text-base xl:text-lg">Verfügbar</th>
      <th class="px-4 py-3 text-sm md:text-base xl:text-lg">Lagerplatz</th>
    </tr>

    <tr class="h-4 bg-luense-background border-b-2 border-gray-300">
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>

    <tr
      v-for="article in articles"
      :key="article.number"
      class="bg-white hover:bg-gray-100 border-b-2 border-r-2 border-l-2 border-gray-300"
    >
      <td class="px-4 text-left text-xs md:text-base py-3">
        {{ article.number }}
      </td>
      <td class="px-4 text-left text-xs md:text-base py-3">
        {{ article.name }}
      </td>
      <td class="px-4 text-left text-xs md:text-base py-3">
        {{ article.supplier }}
      </td>
      <td class="px-4 text-left text-xs md:text-base py-3">
        {{ floatToEuro(article.price) }}
        {{ floatToEuro(article.pseudoPrice, true) }}
      </td>
      <td class="px-4 text-left text-xs md:text-base py-3">
        {{ article.stock }}
      </td>
      <td class="px-4 text-left text-xs md:text-base py-3">
        <span v-for="(item, index) in article.whlocNames" :key="index">
          {{ item }}
        </span>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  props: ["articles"],
  data() {
    return {};
  },
  methods: {
    floatToEuro(float, pseudo = false) {
      if (float == 0) {
        return "";
      }

      var euroCurrency;
      euroCurrency =
        "\u20AC" + float.toLocaleString("nl-NL", { minimumFractionDigits: 2 });

      if (pseudo) {
        var res = "(" + euroCurrency + ")";
        return res;
      }

      return euroCurrency;
    },
  },
};
</script>

<style></style>
