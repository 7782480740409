import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "./APIConfig";
import "./assets/tailwind.css";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const app = createApp(App);

// config
app.config.globalProperties.$API = axios.devInstance;

app.use(router);
app.use(Toast, {
  transition: "Vue-Toastification__fade",
  maxToasts: 20,
  bodyClassName: ["custom-class-1", "custom-class-2"],
});

app.mount("#app");
