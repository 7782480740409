<template>
  <div
    class="w-full min-h-screen bg-luense-background p-2 flex flex-col xl:px-64 sm:pt-0"
  >
    <nav
      class="relative w-full flex flex-wrap items-center justify-between py-4 mb-6"
    >
      <div
        class="container-fluid w-full flex flex-wrap items-center justify-center xl:justify-between"
      >
        <div class="container-fluid">
          <a
            class="flex items-center text-gray-900 hover:text-gray-900 focus:text-gray-900 mt-2 lg:mt-0 mr-1"
            href="#"
          >
            <img
              src="@/assets/luense_logo.png"
              class="min-h-32"
              style="height: 80px"
              alt=""
              loading="lazy"
            />
          </a>
        </div>
      </div>
    </nav>
    <!-- Logo -->
    <!-- Search Input -->
    <div
      class="container-fluid mb-8 w-full flex sm:items-center sm:justify-center xl:justify-start border-2 border-gray-300"
    >
      <div class="w-11/12 h-full">
        <input
          v-model="form.searchText"
          @input="submit"
          type="search"
          class="w-full h-24 px-4 text-xl md:text-4xl bg-white text-gray-800 focus:outline-none"
          placeholder="Artikelnummer oder Name"
        />
      </div>
      <button
        class="bg-luense-green hover:bg-luense-green-hv h-24 w-3/12 md:w-1/12 flex justify-center items-center"
      >
        <svg
          class="w-12 h-12 text-white"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          ></path>
        </svg>
      </button>
    </div>

    <ArticleTable v-if="loading === false && vw > 640" :articles="articles" />
    <ArticleTableMobile
      v-if="loading === false && vw < 640"
      :articles="articles"
    />
    <ArticleTableSkeleton
      :count="this.count.count"
      v-if="loading === true && vw > 640"
    />
    <ArticleTableSkeletonMobile
      :count="this.count.count"
      v-if="loading === true && vw < 640"
    />
  </div>

  <!-- classic design -->
</template>

<script>
import axios from "axios";
import ArticleTable from "@/components/ArticleTable.vue";
import ArticleTableMobile from "@/components/ArticleTableMobile.vue";
import ArticleTableSkeleton from "@/components/ArticleTableSkeleton.vue";
import ArticleTableSkeletonMobile from "@/components/ArticleTableSkeletonMobile.vue";
import { useToast } from "vue-toastification";
export default {
  components: {
    ArticleTable,
    ArticleTableSkeleton,
    ArticleTableMobile,
    ArticleTableSkeletonMobile,
  },
  data() {
    return {
      form: {
        searchText: "",
      },
      articles: [],
      loading: false,
      count: {
        msg: "",
        count: 0,
      },
      clienterrors: {},
      servererror: "",
      countRequest: "",
      searchRequest: "",
      vw: Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      ),
    };
  },
  created() {
    document.title = "Bestände";
  },
  methods: {
    async submit() {
      // Clear info message
      const toast = useToast();
      this.count.msg = "";
      toast.clear();

      // Cancle all pending requests
      if (this.countRequest) {
        this.countRequest.cancel("");
      }
      if (this.searchRequest) {
        this.searchRequest.cancel("");
      }

      // Request Guard
      if (this.form.searchText.length < 3) {
        this.loading = false;
        this.articles = [];
        return;
      }

      try {
        // Clear current info message
        this.count.msg = "";

        await this.$API.get("dbstatus");

        // Initalize loading and set request token
        this.loading = true;
        this.countRequest = axios.CancelToken.source();

        // Send Request to catch search amount count
        var count = await this.$API({
          method: "post",
          url: "search-count",
          data: this.form,
          cancelToken: this.countRequest.token,
        });

        // Handle Response
        this.count = count.data;

        // Display info messages
        if (this.count.msg) {
          if (this.count.count === 0) {
            toast.error(this.count.msg, {
              position: "top-center",
              timeout: 3000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: false,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: false,
              rtl: false,
            });
          } else {
            toast.warning(this.count.msg, {
              position: "top-center",
              timeout: 6000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: false,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: false,
              rtl: false,
            });
          }
        }

        // Send Request to get data
        this.searchRequest = axios.CancelToken.source();
        var response = await this.$API({
          method: "post",
          url: "search",
          data: this.form,
          cancelToken: this.searchRequest.token,
        });

        // Handle response
        this.articles = response.data;
        this.loading = false;
      } catch (error) {
        switch (error.response.data.message) {
          case "SYNC_IN_PROGRESS":
            toast.warning("Bitte warten! Datenbank wird synchronisiert", {
              position: "top-center",
              timeout: 15000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: false,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: false,
              rtl: false,
            });
            break;
          default:
            toast.error("Dienst ist vorrübergehend nicht Verfügbar!", {
              position: "top-center",
              timeout: 3000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: false,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: false,
              rtl: false,
            });
            break;
        }
      }
    },
  },
};
</script>

<style>
body {
  background: white !important;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;700;900&display=swap");

td {
  font-family: "Roboto", sans-serif;
  color: black;
}

th {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
}
</style>
