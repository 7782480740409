import { createRouter, createWebHashHistory } from "vue-router";
import Login from "../views/Login.vue";
import StockList from "../views/StockList.vue";
import axios from "../APIConfig";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Login,
  },
  {
    path: "/bestandsliste",
    name: "Bestandsliste",
    component: StockList,
    beforeEnter: async function (to, from, next) {
      try {
        await axios.devInstance.get("/isauthenticated");
        next();
      } catch (error) {
        return false;
      }
    },
    // // route level code-splitting
    // // this generates a separate chunk (about.[hash].js) for this route
    // // which is lazy-loaded when the route is visited.
    // component: () =>
    //   import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
