<template>
  <!-- header -->
  <div
    class="table--header flex w-full bg-luense-green text-left border-b-2 border-gray-300 text-white px-4 py-3 mb-3"
  >
    <div class="w-1/2 text-md md:text-base xl:text-lg">Artikelnummer</div>
    <div class="w-1/2 text-md md:text-base xl:text-lg">Name</div>
  </div>

  <!-- rows -->
  <div
    v-for="article in articles"
    :key="article.number"
    @click="article.details = !article.details"
    class="table--row flex flex-col w-full bg-white hover:bg-gray-100 border-l-2 border-r-2 border-b-2 border-gray-300"
  >
    <!-- basic -->
    <div class="flex w-full text-sm px-4 py-3">
      <span class="w-1/2">{{ article.number }}</span>
      <span class="w-1/2">{{ article.name }}</span>
    </div>

    <!-- extended -->
    <Transition>
      <div v-if="article.details === true" class="flex w-full px-4 py-3">
        <div class="w-1/2 text-left text-sm md:text-base">
          <div class="flex flex-col gap-3">
            <span>Verfügbar: {{ article.stock }}</span>
            <div class="w-full">
              <span>Platz: </span>
              <span v-for="(item, index) in article.whlocNames" :key="index">
                {{ item }}
              </span>
            </div>
          </div>
        </div>
        <div class="w-1/2 text-left text-sm md:text-base">
          <div class="flex flex-col gap-3">
            <span>Hersteller: {{ article.supplier }}</span>
            <span
              >Preis: {{ floatToEuro(article.price) }}
              {{ floatToEuro(article.pseudoPrice, true) }}</span
            >
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  props: ["articles"],
  data() {
    return {
      vw: Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      ),
    };
  },
  methods: {
    floatToEuro(float, pseudo = false) {
      if (float == 0) {
        return "";
      }

      var euroCurrency;
      euroCurrency =
        "\u20AC" + float.toLocaleString("nl-NL", { minimumFractionDigits: 2 });

      if (pseudo) {
        var res = "(" + euroCurrency + ")";
        return res;
      }

      return euroCurrency;
    },
  },
};
</script>

<style>
.table--row {
  font-family: "Roboto", sans-serif;
  color: black;
}

.v-enter-active {
  transition: all 0.3s ease-out;
}

.v-enter-from,
.v-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.table--header {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
}
</style>
