import axios from "axios";
import router from "./router";
import { useToast } from "vue-toastification";

function createInstance(baseURL) {
  return axios.create({
    baseURL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

const devInstance = createInstance(process.env.VUE_APP_BASEURL);
devInstance.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem("accessToken");
  config.headers["Authorization"] = "Bearer " + accessToken;
  return config;
});

devInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  async (error) => {
    const request = error.config;
    const status = error.response ? error.response.status : null;

    // // Check if Access Token is expired, try to refresh
    // if (status === 422 && !request._retry) {
    //   // Set refresh done flag
    //   request._retry = true;

    //   // Get new Tokens and retry origin request
    //   try {
    //     let response = await devInstance.get("/auth/refresh");
    //     console.log(response);
    //     // localstorage
    //     return devInstance.request(request);
    //   } catch (err) {
    //     // rejects the error to be handled again
    //     throw new Error("Unauthorized");
    //   }
    // }

    if (status === 401 || status === 422 || status === 421) {
      router.push("/");
      const toast = useToast();
      toast.error("Session abgelaufen! Bitte erneut einloggen", {
        position: "top-center",
        timeout: 6000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: false,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: false,
        rtl: false,
      });
      return Promise.reject(new Error("Ungültige Session!"));
    }

    // If refresh fails
    if (status === 403 && request._retry) {
      console.log("Deine Session ist abgelaufen");
    }

    return Promise.reject(error);
  }
);

export default {
  devInstance,
};
