<template>
  <div
    class="login h-screen overflow-hidden flex items-center justify-center bg-luense-background"
  >
    <div
      class="w-full min-h-screen bg-gray-50 flex flex-col sm:justify-center items-center pt-6 sm:pt-0"
    >
      <div class="w-full sm:max-w-md p-5 mx-auto">
        <img class="mb-12 m-auto" alt="Lünse" src="@/assets/luense_logo.png" />
        <!-- <h2 class="mb-12 text-center text-5xl font-extrabold">
            Welcome.</h2> -->
        <form @submit.prevent="submit">
          <div class="mb-4">
            <label class="block mb-1 font-extrabold" for="email"
              >Benutzer</label
            >
            <input
              id="user"
              type="text"
              name="user"
              v-model="form.user"
              class="py-2 px-3 border border-gray-300 focus:border-luense-green focus:outline-none focus:ring focus:ring-luense-green focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
            />
          </div>
          <div class="mb-4">
            <label class="block mb-1 font-extrabold" for="password"
              >Passwort</label
            >
            <input
              id="password"
              type="password"
              name="password"
              v-model="form.password"
              class="py-2 px-3 border border-gray-300 focus:border-luense-green focus:outline-none focus:ring focus:ring-luense-green focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
            />
          </div>
          <div class="mt-6">
            <button
              class="w-full inline-flex items-center justify-center px-4 py-2 bg-luense-green border border-transparent rounded-md font-semibold capitalize text-white hover:bg-luense-green-hv active:bg-red-700 focus:outline-none focus:border-luense-green-hv focus:ring focus:ring-red-200 disabled:opacity-25 transition"
            >
              Einloggen
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      form: {
        user: "",
        password: "",
      },
      clienterrors: {},
      servererror: "",
    };
  },
  created() {
    document.title = "Login";
  },
  methods: {
    async submit() {
      const toast = useToast();
      try {
        var response = await this.$API.post("/login", this.form);
        localStorage.setItem("accessToken", response.data.access_token);
        this.$router.push("/bestandsliste");
      } catch (error) {
        toast.error("Ungültige Zugangsdaten!", {
          position: "top-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: false,
          rtl: false,
        });
      }
    },
  },
};
</script>

<style>
/* This will affect all 6 containers */
.Vue-Toastification__toast-body.custom-class-1 {
  font-size: 25px;
}
</style>
